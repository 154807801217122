import WebPImage from "@/components/WebPImage";
import TransLink from "@/utils/TransLink";
import { router, usePage } from "@inertiajs/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import appstore from "../assets/Appstore.svg";
import logofooter from "../assets/LogoFooter.png";
import logofooterwebp from "../assets/LogoFooter.webp";
import logofooter2 from "../assets/LogoFooter2.png";
import logofooter2webp from "../assets/LogoFooter2.webp";
import phone from "../assets/Phone.png";
import phonewebp from "../assets/Phone.webp";
import playstore from "../assets/Playstore.svg";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


const Footer = () => {
    const { social, menus, copyright, locale } = usePage().props;
    const { executeRecaptcha } = useGoogleReCaptcha()

    const { t } = useTranslation(["common", "validation", "glossary"]);

    const [isHidden, setIsHidden] = useState(
        window.location.pathname.startsWith("/password/reset"),
    );

    useEffect(() => {
        const handleLocationChange = () => {
            setIsHidden(window.location.pathname.startsWith("/password/reset"));
        };

        window.addEventListener("popstate", handleLocationChange);

        // Using 'pushState' and 'replaceState'
        const originalPushState = window.history.pushState;
        const originalReplaceState = window.history.replaceState;

        window.history.pushState = function (...args) {
            originalPushState.apply(window.history, args);
            handleLocationChange();
        };

        window.history.replaceState = function (...args) {
            originalReplaceState.apply(window.history, args);
            handleLocationChange();
        };

        return () => {
            window.removeEventListener("popstate", handleLocationChange);
            window.history.pushState = originalPushState;
            window.history.replaceState = originalReplaceState;
        };
    }, []);

    const handleSubscribe = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const form = e.target as HTMLFormElement
        const emailInput = form.elements.namedItem('email') as HTMLInputElement
        const email = emailInput.value

        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        executeRecaptcha("form").then((gReCaptchaToken: string) => {


            router.post(`/${locale}/ajax/newsletter`, { email, "g-recaptcha-response": gReCaptchaToken }, {
                preserveScroll: true,
                onSuccess: (e) => {

                }
            })
        })

    }

    return (
        <footer className={`${isHidden ? "hidden" : "block"} flex flex-col [@media(max-height:950px)]:h-[80%] h-[90%] mt-4 z-10`}>
            <div className="bg-gradient-to-r from-[#070F2B] to-[#1D24CA] ">
                <div className="xl:mx-60 mx:full flex flex-col md:flex-row items-center justify-between text-white">
                    <div className="md:w-2/3 xl:ml-12 ml-0 p-7 mx-auto ">
                        <h2 className="xl:text-5xl text-3xl text-center md:text-left font-bold mb-4">
                            {t("accommodation_in_thassos", { ns: "glossary" })}
                        </h2>
                        <p className="mb-6 text-md xl:pr-40 text-left md:text-left pr-0 font-extralight">
                            {t("download_app", { ns: "glossary" })}
                        </p>
                    </div>

                    <div className="md:w-1/3 flex flex-col items-center justify-center mt-4 md:mt-0">
                        <div className="flex gap-6 flex-col">
                            <a href="https://play.google.com/store/apps/details?id=com.visithassos.app" className="block">
                                <img
                                    src={playstore}
                                    alt="Get it on Google Play"
                                    className="h-12"
                                />
                            </a>
                            <a href="https://apps.apple.com/app/visit-thassos/id6736649704" className="block">
                                <img
                                    src={appstore}
                                    alt="Download on the App Store"
                                    className="h-12"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="md:w-1/3 w-full mt-8 md:mt-0 flex items-center justify-center">
                        <div className=" md:w-auto">
                            <WebPImage
                                className="max-w-full h-auto"
                                srcWebP={phonewebp}
                                srcFallback={phone}
                                alt="App on phone"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#0D0D25] text-white py-12">
                <div className="container mx-auto px-4 text-center">
                    <div className="flex flex-col items-center mb-8">
                        <WebPImage
                            className="max-w-full h-auto"
                            srcWebP={logofooterwebp}
                            srcFallback={logofooter}
                            alt="Thassos Greece"
                        />
                        <div className="flex space-x-5 mt-8">
                            {social?.map((item, index) => {
                                if (!item.icon && !item.image) {
                                    return null;
                                }
                                return (
                                    <a
                                        key={index}
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-white hover:scale-125 transition"
                                    >
                                        {item.icon ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.icon,
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={item.image}
                                                loading="lazy"
                                                alt={item.name}
                                                className="h-5"
                                            />
                                        )}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                    <div className="text-center mb-8">
                        <h2 className="md:text-lg text-md font-bold mb-2">
                            {t("subscribe_to_our_newsletter", { ns: "glossary" })}
                        </h2>
                        <p className="text-md font-light">
                            {t("sign_up_to_receive_news_events", { ns: "glossary" })}
                        </p>
                        <form onSubmit={(e) => handleSubscribe(e)} className="mt-4 flex justify-center">
                            <input
                                required
                                type="email"
                                placeholder="Email"
                                name="email"
                                className="px-4 py-2 rounded-l-full text-black"
                            />
                            <button type="submit" title={t("subscribe", { ns: "glossary" })} className="bg-[#1D24CA] text-white px-4 py-2 rounded-r-full">
                                {t("subscribe", { ns: "glossary" })}
                            </button>
                        </form>
                    </div>
                    <div className="flex flex-col items-center mb-8">
                        <WebPImage
                            srcWebP={logofooter2webp}
                            srcFallback={logofooter2}
                            alt="Municipality of Thassos"
                        />
                        <div className="text-md font-extralight">
                            {menus?.footer?.map((link, index) => (
                                <React.Fragment key={index}>
                                    {link.url == '/admin/login' || link.url == '/admin/register' ? (
                                        <>
                                            <a target={link.target} href={link.url}>{link.title}</a>
                                        </>
                                    ) : (
                                        <>
                                            <TransLink
                                                url={link.url}
                                                target={link.target}
                                                className="text-white hover:underline mx-1"
                                            >
                                                {link.title}
                                            </TransLink>
                                        </>)
                                    }

                                    {index < menus?.footer?.length - 1 && (
                                        <span className="mx-1">|</span>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between text-xs mt-8 w-full max-w-screen-lg mx-auto">
                        <div
                            className="text-sm"
                            dangerouslySetInnerHTML={{ __html: copyright }}
                        ></div>
                        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-1 mt-8 md:mt-0 items-center">
                            <div>
                                <a
                                    className="text-sm mr-1 hover:underline"
                                    href="https://lioncode.gr/"
                                >
                                    Κατασκευή Ιστοσελίδων
                                </a>
                                <a className="font-bold text-sm">
                                    Lioncode Your IT People
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
